<template>
  <DetailsSection :header="'Absences'" :headerCaption="'Show vacations, sick leave and days off.'" :hasFooter="false">
    <template #body>
      <div class="mt-6 grid grid-cols-4 gap-6"></div>
    </template>

    <template #footer> </template>
  </DetailsSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DetailsSection from "@/components/details/DetailsSection.vue";

export default defineComponent({
  name: "EmployeeDetailsPageAbsences",
  components: {
    DetailsSection
  },
  setup() {
    return {};
  }
});
</script>
