
import { defineComponent } from "vue";
import DetailsSection from "@/components/details/DetailsSection.vue";

export default defineComponent({
  name: "EmployeeDetailsPageAbsences",
  components: {
    DetailsSection
  },
  setup() {
    return {};
  }
});
